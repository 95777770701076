export enum FileType {
    DEFAULT_SESSION_TERM = 'DEFAULT_SESSION_TERM',
    DEFAULT_SESSION_PROMISE = 'DEFAULT_SESSION_PROMISE',
    CNAB400 = 'CNAB400',
    CNAB444 = 'CNAB444',
    CNAB240 = 'CNAB240',
    DOCUMENT = 'DOCUMENT',
    VIPOSA_SESSION_TERM = 'VIPOSA_SESSION_TERM',
    SERVICES_SESSION_TERM = 'SERVICES_SESSION_TERM',
    BLUBRASIL_SESSION_TERM = 'BLUBRASIL_SESSION_TERM',
    CARGOLIFT_SESSION_TERM = 'CARGOLIFT_SESSION_TERM',
    IMPORTED_DOCUMENT = 'IMPORTED_DOCUMENT',
    IMPORTED_DATA_DOCUMENT = 'IMPORTED_DATA_DOCUMENT',
    EXPORTED_DATA_DOCUMENT = 'EXPORTED_DATA_DOCUMENT',
    CNAB500 = 'CNAB500',
    INVOICE_DOCUMENT = 'INVOICE_DOCUMENT'
}
